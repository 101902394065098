import React, { useEffect, useContext, useState } from "react";
import Logo from "../images/bivver-logo-branco.png";
import { Skeleton } from "antd";
import { AuthContext } from "../context/GlobalContextProvider";
import api from "../api";

function Footer() {
  const [page, setPage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPage();
  }, []);

  const getPage = async () => {
    setLoading(true);
    const { data } = await api.get("/contato");
    setPage(data);
    setLoading(false);
  };
  const context = useContext(AuthContext);
  return (
    <footer
      style={{
        float: "bottom",
        backgroundColor: "#212121",
      }}
      className="text-left"
    >
      <div
        style={{ fontSize: "1rem" }}
        className="grid grid-cols-1 md:grid-cols-4 gap-4 m-10 md:mx-20 md:mb-0 md:mt-16"
      >
        <div className="col-span-1">
          <a href="#topo" className="flex ">
            <img src={Logo} className="ml-8 mt-5 w-1/2" />
          </a>
        </div>
        <Skeleton active loading={loading}>
          <div
            className="col-span-1 lg:col-start-2"
            style={{ fontSize: "15px" }}
          >
            <div className="my-3">Base administrativa</div>
            <div className="my-3">Endereço: {page?.endereco}</div>
            <div className="my-3">WhatsApp: {page?.telefone}</div>
            <div className="my-3">Email: {page?.email}</div>
          </div>
        </Skeleton>
        <Skeleton active loading={loading}>
          <div
            className="col-span-1 lg:col-start-3"
            style={{ fontSize: "15px" }}
          >
            <div className="my-3">Sede comcercial</div>
            <div className="my-3">Endereço: {page?.endereco2}</div>
            <div className="my-3">WhatsApp: {page?.telefone2}</div>
            <div className="my-3">Email: {page?.email2}</div>
          </div>
        </Skeleton>

        <div className="col-span-1 lg:col-start-4">
          <b style={{ fontFamily: "Source Sans Pro" }} className="text-lg">
            Redes sociais:
          </b>
          <div className="flex mt-2">
            <a
              className="barraFinal"
              href="https://www.facebook.com/Bivver-Empreendimentos-109070777371707"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-facebook -ml-3 text-4xl"></i>
            </a>
            <a
              className="ml-1 barraFinal"
              href="https://www.instagram.com/bivver_empreendimentos"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-instagram text-4xl"></i>
            </a>
            <a
              className="ml-1 barraFinal"
              href="https://www.linkedin.com/company/bivver-empreendimentos/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="bx bxl-linkedin text-4xl"></i>
            </a>
          </div>
        </div>
        <div className="col-span-1 lg:col-start-4" style={{ fontSize: "15px" }}>
          {context?.user && (
            <>
              <div className="mt-4">Logado como: {context?.user?.username}</div>
              <div
                className="mt-4 cursor-pointer"
                onClick={context?.handleLogout}
              >
                Fazer logout?
              </div>
            </>
          )}
        </div>
      </div>
      <section style={{ fontSize: "0.85rem" }} className="p-2 text-white">
        <div>
          © 2020 | Desenvolvido por{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/in/ricardo-reinert-572a96145/"
          >
            Ricardo Reinert Karnopp
          </a>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
