import React from "react";
import { Affix } from "antd";
import TelefoneIcon from "../images/bx-phone.svg";
import WhatsAppIcone from "../images/bxl-whatsapp.svg";
import MailIcone from "../images/bx-envelope.svg";
import HomeIcone from "../images/bx-home-alt.svg";
import { Link } from "gatsby";

export default function FixedBar() {
  return (
    <Affix offsetBottom={0}>
      <div style={{ background: "#D27A34" }}>
        <center>
          <div className="h-16 grid grid-cols-4 lg:gap-10 gap-5 w-3/4 self-center">
            <div style={{ borderRight: "1px solid white", }} className='lg:p-2'>
              <Link to="/" className="flex">
                <img
                  className="lg:mr-3 lg:mt-2 mt-6 lg:h8"
                  style={{
                    filter: "invert(100%)",
                    color: "white",
                  }}
                  src={HomeIcone}
                />
                <h5
                  style={{ textAlign: "left" }}
                  className="lg:block hidden text-white"
                >
                  Ir para o <br />{" "}
                  <h4 className="font-bold text-lg text-white -mt-1">ínicio</h4>
                </h5>
              </Link>
            </div>
            <div style={{ borderRight: "1px solid white", }} className='lg:p-2'>
              <a href="tel:+5547997873689" className="flex">
                <img
                  className="lg:mr-3 lg:mt-2 mt-6 lg:h8"
                  style={{
                    filter: "invert(100%)",
                    color: "white",
                  }}
                  src={TelefoneIcon}
                />
                <h5
                  style={{ textAlign: "left" }}
                  className="lg:block text-white hidden"
                >
                  Ligue para nós <br />
                  <h4 className="font-bold text-lg text-white -mt-1">
                    (47) 99787-3689
                  </h4>
                </h5>
              </a>
            </div>
            <div style={{ borderRight: "1px solid white", }} className='lg:p-2'>
              <a
                href="https://wa.me/554797873689"
                rel="noopener noreferrer"
                target="_blank"
                className="flex"
              >
                <img
                  className="lg:mr-3 lg:mt-2 mt-6 lg:h8"
                  style={{
                    filter: "invert(100%)",
                    color: "white",
                  }}
                  src={WhatsAppIcone}
                />
                <h5
                  style={{ textAlign: "left" }}
                  className="lg:block text-white hidden"
                >
                  Contato via
                  <br />
                  <h4 className="font-bold text-lg text-white -mt-1">
                    WhatsApp
                  </h4>
                </h5>
              </a>
            </div>
            <div style={{ }} className='lg:p-2'>
              <a href="/email" className="flex">
                <img
                  className="lg:mr-3 lg:mt-2 mt-6 lg:h8"
                  style={{
                    filter: "invert(100%)",
                    color: "white",
                  }}
                  src={MailIcone}
                />
                <h5
                  style={{ textAlign: "left" }}
                  className="lg:block text-white hidden"
                >
                  Envie um
                  <h4 className="font-bold text-lg text-white -mt-1">E-mail</h4>
                </h5>
              </a>
            </div>
          </div>
        </center>
      </div>
    </Affix>
  );
}
