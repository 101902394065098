/* eslint-disable react/jsx-key */
typeof window !== "undefined" && require("boxicons");
import Link from "gatsby-link";
import PropTypes from "prop-types";
import React from "react";

const Navigation = ({ isExpanded }) => {
  return (
    <nav className={`nav md:inline-flex ${isExpanded ? `block` : `hidden`}`}>
      <ul className="md:flex">
        <li>
          <Link to="/">Início</Link>
        </li>
        <li>
          <Link to="/institucional">Institucional</Link>
        </li>
        <li>
          <Link to="/empreendimentos">Empreendimentos</Link>
        </li>
        {/* <li className="icon-margin">
          <Link
            style={{ position: "relative", bottom: "0.41rem" }}
            to="/nossasLinhas"
          >
            Nossas Linhas{" "}
            <box-icon
              style={{ position: "relative", top: "0.38rem" }}
              size="sm"
              name="chevron-down"
            />
          </Link>
          <ul className="row" style={{ maxWidth: 600 }}>
            <li className="col-md-6 d-flex flex-column">
              <Link to="/nossasLinhas/bivverDream">Bivver Dream</Link>
            </li>
            <li className="col-md-6 d-flex flex-column">
              <Link to="/nossasLinhas/bivverClub">Bivver Club</Link>
            </li>
            <li className="col-md-6 d-flex flex-column">
              <Link to="/nossasLinhas/bivverExclusivity">
                Bivver Exclusivity
              </Link>
            </li>
          </ul>
        </li> */}
        <li>
          <Link to="http://bivver.com.br/blog">Blog</Link>
        </li>
        <li>
          <Link to="/contato">Contato</Link>
        </li>
      </ul>
    </nav>
  );
};
Navigation.propTypes = {
  isExpanded: PropTypes.bool,
  setLoadingPage: PropTypes.func,
  data: PropTypes.array,
};

export default Navigation;
