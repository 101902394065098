import React from "react";
import PropTypes from "prop-types";

export default function PageTitle({ imageUrl, title, text }) {
  return (
    <React.Fragment>
      <div
        className="text-center md:mT-15 py-10"
        style={{
          background: "#FAFAFA 0% 0% no-repeat padding-box",
        }}
      >
        <center>
          {imageUrl && (
            <img className="lg:w-1/6 w-1/2 m-5 self-center" src={imageUrl} />
          )}
          {text && (
            <p
              style={{
                textAlign: "center",
                fontWeight: "600",
                position: "relative",
                top: "0.6rem",
                fontSize: "24px",
              }}
              className="lg:w-3/5 mt-4"
            >
              {text}
            </p>
          )}
          <h1>{title}</h1>
        </center>
      </div>
    </React.Fragment>
  );
}

PageTitle.propTypes = {
  imageUrl: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};
